import logo from '../assets/logo.svg';
import React, { useRef, useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./website-navbar.css";
import { VscMenu, VscClose } from "react-icons/vsc";
import styled from "styled-components";
import { FiSearch, FiShoppingCart } from "react-icons/fi";
import "@szhsin/react-menu/dist/index.css";
import { BlackButton } from "./black-button";
import { useSelector } from "react-redux";
import { IAppState } from "../store/store";
import { NavbarUserIcon } from "./navbar-components";
import whiteLogo from "../assets/logo-white.png";

export const WebsiteHeader = () => {
  const isLoggedIn = useSelector((state: IAppState) => state.auth.token);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const linksRef = useRef<HTMLUListElement>(null);
  const linksContainerRef = useRef<HTMLDivElement>(null);
  const [isNavOpen, setNav] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const closeNav = () => setNav(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (linksRef.current && linksContainerRef.current) {
      if (isNavOpen) {
        linksContainerRef.current.style.right = `0`;
      } else {
        linksContainerRef.current.style.right = `-${500}px`;
      }
    }
  }, [isNavOpen]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        toggleRef.current?.contains(event.target as Node) ||
        toggleRef.current?.childNodes[0]?.contains(event.target as Node)
      ) {
        setNav(!isNavOpen);
        return;
      }

      if (
        isNavOpen &&
        !linksContainerRef?.current?.contains(event.target as Node)
      ) {
        closeNav();
        return;
      }
    };
    const handleScroll = () => {
      if (isNavOpen) {
        closeNav();
      }
    };
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNavOpen]);

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    closeNav();
    setSearchQuery("");
    navigate(`/campaign-search/${searchQuery}`);
  };

  return (
    <Wrapper isNavOpen={isNavOpen}>
      <nav className="max-container md:px-16 Navbar pt-8 pb-6 flex gap-8 2xl:gap-10 items-center whitespace-nowrap overflow-x-hidden">
        <NavLink to="/">
          <img src={logo} className="logo w-[180px]" alt="LOGO" />
        </NavLink>

        <form onSubmit={handleSubmitSearch}>
          <div className="relative flex items-center justify-between xl:ml-10 border-2 border-black border-solid rounded-[21px] pl-10 pr-4 h-[41px]">
            <FiSearch className="absolute center_y left-4 text-2xl" />
            <input
              type="input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="outline-none ml-3"
            />
            {/* <Link to={`/campaign-search/${searchQuery}`}>Search</Link> */}
            <button type="submit">Search</button>
          </div>
        </form>
        <NavLink to="/about">About us</NavLink>
        <NavLink to="/how-it-works">How it works</NavLink>
        <NavLink to="/campaigns">Shop</NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
        <NavLink to="/campaigns">Campaigns</NavLink>

        {!isLoggedIn ? (
          <NavLink to="/login">
            <BlackButton text="Login" />
          </NavLink>
        ) : null}

        {/* <NavLink to="/cart">
          <button className="relative top-[5px]">
            <FiShoppingCart size={24} fontWeight={700} />
          </button>
        </NavLink> */}
        {/* <NavLink to="#">
          <button className="relative top-[5px]">
            <FiShoppingCart size={24} fontWeight={700} />
          </button>
        </NavLink> */}

        <NavbarUserIcon />
      </nav>

      <div className="mobile-container">
        <nav className="mobile">
          <NavLink to="/">
            <img src={logo} className="w-[100px]" alt="LOGO" />
          </NavLink>

          <div className="flex gap-3 sm:gap-5 items-center">
            {!isLoggedIn ? (
              <NavLink to="/login">
                <button className="bg-black px-4 py-[6px] rounded-[20px] text-sm text-white">
                  Login
                </button>
              </NavLink>
            ) : null}

            {/* <NavLink to="#">
              <button className="relative top-1">
                <FiShoppingCart size={20} fontWeight={700} />
              </button>
            </NavLink> */}
            {/* <NavLink to="/cart">
              <button className="relative top-1">
                <FiShoppingCart size={20} fontWeight={700} />
              </button>
            </NavLink> */}

            <NavbarUserIcon />

            <button className="toggle" ref={toggleRef}>
              {isNavOpen ? (
                <VscClose size={20} fontWeight={700} />
              ) : (
                <VscMenu size={20} fontWeight={700} />
              )}
            </button>
          </div>
        </nav>
        <div
          className="bg-black text-white p-5 pl-6 fixed top-0 duration-700 w-[287px] h-screen [z-index:100000]"
          ref={linksContainerRef}
        >
          <div className="flex items-center justify-between">
            <img src={whiteLogo} className="w-[90px]" alt="" />
            <button className="toggle" onClick={closeNav}>
              <VscClose size={20} fontWeight={"bold"} />
            </button>
          </div>

          {/* <div className="relative mt-7">
            <FiSearch className="absolute center_y left-4" size={18} />
            <input
              type="text"
              className="outline-none border-2 border-white bg-black text-white border-solid rounded-[21px] pl-10 pr-4 h-[41px]"
            />
          </div> */}
          <form onSubmit={handleSubmitSearch} className="mt-7">
            <div className="relative flex items-center justify-between xl:ml-10 border-2 border-white bg-black border-solid rounded-[21px] pl-0.5 pr-4 h-[41px]">
              <FiSearch className="absolute center_y left-1 text-2xl" />
              <input
                type="input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="outline-none ml-2 pl-5 bg-inherit "
              />
              <button type="submit" className="text-white absolute right-4">
                Search
              </button>
            </div>
          </form>

          <ul ref={linksRef} className="flex flex-col gap-7 mt-6">
            <li>
              <NavLink to="/about" onClick={closeNav}>
                About us
              </NavLink>
            </li>
            <li>
              <NavLink to="/how-it-works" onClick={closeNav}>
                How it works
              </NavLink>
            </li>
            <li>
              <NavLink to="/campaigns" onClick={closeNav}>
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" onClick={closeNav}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink to="/campaigns" onClick={closeNav}>
                Campaigns
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

interface WrapperProps {
  isNavOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  nav:not(.mobile) {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  nav.gap-8 {
    @media screen and (min-width: 961px) and (max-width: 1078px) {
      img.logo {
        width: 150px;
      }
      gap: 1rem;
    }
    @media screen and (min-width: 1079px) and (max-width: 1187px) {
      gap: 1.2rem;
    }
  }
  .mobile-container {
    @media screen and (min-width: 961px) {
      display: none;
    }

    padding: 2em 0;
    position: relative;
  }
  .mobile {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 1rem; */
    svg {
      font-size: 2rem;
    }
  }
  .search-input {
    img {
      top: 15%;
      left: 2%;
    }
    input {
      width: 100%;
      max-width: 600px;
    }
  }
  li.flex {
    /* justify-content: space-between; */
    gap: 3em;
    align-items: center;
  }
`;
